import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div className="App-border">
          <p className="App-text">
            IN PROGRESS
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
